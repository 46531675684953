/* eslint-disable no-useless-call */
import React from 'react';
import * as RequestLoginBP from './blueprints/auth/request-login';
import * as ProcessLoginBP from './blueprints/auth/process-login';
import { seamlessAuth, seamlessClient } from './seamless';
import { MoopsyAuthState } from '@moopsyjs/react';
import { Router } from './src/router';
import { CompleteLogin } from './src/auth/complete';

export const App = (): React.ReactElement => {
  const authStatus = seamlessAuth.useAuthStatus();
  const requestLoginMutation = seamlessClient.useMutation<RequestLoginBP.Plug>(RequestLoginBP);
  const processLoginMutation = seamlessClient.useMutation<ProcessLoginBP.Plug>(ProcessLoginBP);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  React.useEffect(() => {
    const usp = new window.URLSearchParams(window.location.search);
    const params = {
      SAMLResponse: usp.get('SAMLResponse') as string,
      RelayState: usp.get('RelayState') as string
    };

    if (window.sessionStorage.token == null && window.location.pathname !== '/iauth') {
      window.location.href = `https://hiyllo.f.hiyllo.cloud/ia/${window.location.host}?returnRedirect=${encodeURIComponent(window.location.href)}`;
    }
  }, []);

  if(window.location.pathname === '/iauth') {
    return <CompleteLogin/>;
  }


  if (authStatus === MoopsyAuthState.loggedIn) {
    return <Router/>;
  }

  return (
    <div>
      Logging in...
    </div>
  );
};
