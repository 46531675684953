import React from "react";

import * as CompleteSamlSSOLoginBP from "../../blueprints/auth/process-login";
import { LoadingPage } from "@hiyllo/ux/standard-loader";
import { AuthExtensionStatus } from "@moopsyjs/react/main";
import { seamlessAuth, seamlessClient } from "../../seamless";

export const CompleteLogin = React.memo(function CompleteLogin(): JSX.Element {
  const authStatus = seamlessAuth.useAuthStatus();
  const completeSamlSSOLoginMutation =
  seamlessClient.useMutation<CompleteSamlSSOLoginBP.Plug>(CompleteSamlSSOLoginBP);
  const loginPendingRef = React.useRef(false);
  const authStatusRef = React.useRef(authStatus);
  authStatusRef.current = authStatus;

  React.useEffect(() => {
    void (async () => {
      if (loginPendingRef.current) return;
      if (authStatusRef.current !== AuthExtensionStatus.loggedOut) return;

      loginPendingRef.current = true;
      console.log("[SSO] Performing SSO Login");

      const usp = new window.URLSearchParams(window.location.search);

        const { token } = await completeSamlSSOLoginMutation.call({
          token: usp.get("token") ?? "",
        });

        window.sessionStorage.setItem('token', token);
        window.history.pushState({}, '', "/");
        window.location.reload();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <LoadingPage />;
});
